import mixpanel, { Config } from 'mixpanel-browser';

import { getPatientEventProps } from '@shared/stores/utils';

type EventProps = Record<string, unknown>;

// Hard coded practice ids for demo practices as we only have two in production
const demoPracticeIds = ['924d560a-dbaf-43c8-aa8a-0c1688a79cd4', '56225f5b-2e66-4580-9f97-cee997c4d721'];
const getPracticeIdFromTreatmentGuideId = (treatmentGuideId: string): string => {
  return treatmentGuideId.split(':')[0] || '';
};

export const initMixpanel = (config?: Partial<Config>) => {
  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, config);
};
if (!import.meta.env.VITEST) {
  initMixpanel({
    ...(import.meta.env.VITE_ENVIRONMENT === 'prd' && { record_sessions_percent: 100 }),
  });
}

const actions = {
  /**
   *
   * @param name name of the event
   * @param props additional properties to be stored again an event e.g. {'Practice name': 'Gums n Roses'}
   */
  track: (name: string, props?: EventProps) => {
    const patientEventProps = getPatientEventProps();
    const treatmentGuideId = localStorage.getItem('treatmentGuideId') || '';
    const practiceId = getPracticeIdFromTreatmentGuideId(treatmentGuideId);

    if (name === 'Opted out of analytics') {
      mixpanel.track(name);
      return;
    }

    const isDemo = patientEventProps.demo || (practiceId && demoPracticeIds.includes(practiceId));

    if (!isDemo) {
      /**
       * Cookiebot allows the user to toggle consent for statistics, if they do so and click the 'Allow selection' button
       * or if they click the 'Allow all' button we can track their data through Mixpanel
       * we can confirm this anywhere within the app by checking 'window.Cookiebot?.consent.statistics'
       */
      if (window.Cookiebot?.consent?.statistics || name === 'Opted in for analytics') {
        mixpanel.track(name, { ...patientEventProps, ...props });
      }
    }
  },
  identify: (trackingId: string, practiceName: string) => {
    if (window.Cookiebot?.consent?.statistics) {
      mixpanel.identify(trackingId);
      mixpanel.people.set({ 'Practice name': practiceName, 'userType': 'patient' });
    }
  },
  reset: () => {
    if (window.Cookiebot?.consent?.statistics) mixpanel.reset();
  },
};

export const PatientMixpanel = actions;
